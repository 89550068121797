<template>
	<div class="pui-form">
		<iframe
			width="100%"
			height="100%"
			:src="aemetUrl"
			frameborder="0"
			v-if="this.aemetUrl"
			scrolling="yes"
		></iframe>
		<!-- footer -->
		<pui-form-footer />
	</div>
</template>

<script>
export default {
	name: 'external',
	data() {
		return {
			aemetUrl: null
		};
	},
	mounted() {
		this.aemetUrl = 'https://www.aemet.es/es/eltiempo/prediccion/avisos';
	}
};
</script>

<style scoped>
</style>
